<template>
  <div class="container">
    <sub-header :pathList="pathList" :title="title"></sub-header>

    <div id="contents" class="contents">
      <div class="title-sort-box">
        <h3>{{ $t('rule.title') }}</h3>
        <div class="sort-side">
          <label class="search-label ic-bullet">
            {{ $t('rule.search1') }}
          </label>
          <span style="width: 120px;">
            <select
              class="isf term-sel"
              style="width: 144px;"
              v-model="alarmItemCode"
            >
              <option value="">{{ $t('rule.search1-item1') }}</option>
              <option
                v-for="(alarmRuleItem, index) in alarmRuleItems"
                :key="index"
                :value="alarmRuleItem.codeName"
                >{{ alarmRuleItem.description }}</option
              >
            </select>
          </span>
          <select
            class="isf term-sel"
            id="search_condition"
            v-model="searchCondition"
          >
            <option value="">
              {{ $t('rule.search2-condition1') }}
            </option>
            <option value="alarmRuleId">
              {{ $t('rule.search2-condition2') }}
            </option>
            <option value="alarmRuleNm">
              {{ $t('rule.search2-condition3') }}
            </option>
            <option value="crtrId">
              {{ $t('rule.search2-condition4') }}
            </option>
          </select>
          <span class="search-icon-wrap">
            <input
              type="text"
              class="isf"
              v-model="searchData"
              @keyup.enter="onSearch"
            />
            <button class="ic-search" @click="onSearch">
              search
            </button>
          </span>
          <button class="bt-md bt-default" @click="goRuleInsert()">
            <span>{{ $t('rule.btn-register') }}</span>
          </button>
        </div>
      </div>
      <list-form
        :fetchApi="getRuleList"
        :columns="columns"
        :page="page"
        @detail="goRuleDetail"
        ref="ruleList"
      ></list-form>
    </div>
  </div>
</template>

<script>
import CommonMixins from '@/mixins/CommonMixins';
import ListForm from '@/components/common/ListForm.vue';
import { fetchRuleList } from '@/api/subscriptionApi';

export default {
  data() {
    return {
      pathList: [
        this.$i18n.t('menu.home'),
        this.$i18n.t('menu.subscriptionAdmin'),
        this.$i18n.t('subMenu.rule'),
      ],
      title: {
        mainTitle: this.$i18n.t('menu.subscriptionAdmin'),
        smallTitle: this.$i18n.t('menu.subscriptionAdmin-eng'),
      },
      columns: [
        {
          label: this.$i18n.t('rule.table-column1'),
          field: 'alarmRuleId',
          width: '15%',
          sortable: false,
        },
        {
          label: this.$i18n.t('rule.table-column2'),
          field: 'alarmRuleNm',
          width: '53%',
          sortable: false,
        },
        {
          label: this.$i18n.t('rule.table-column3'),
          field: 'creatDt',
          width: '15%',
          sortable: false,
        },
        {
          label: this.$i18n.t('rule.table-column4'),
          field: 'crtrId',
          width: '10%',
          sortable: false,
        },
      ],
      page: this.$route?.params?.page || 1,
      searchCondition: '',
      searchData: '',
      alarmItemCode: '',
    };
  },
  mixins: [CommonMixins],
  components: { ListForm },
  methods: {
    getRuleList(params) {
      return fetchRuleList(params);
    },
    onSearch() {
      let params = {
        page: 1,
        searchCondition: this.searchCondition,
        searchData: this.searchData,
        alarmItemCode: this.alarmItemCode,
      };

      this.$refs.ruleList.onSearch(params);
    },
    goRuleDetail({ alarmRuleSn, page }) {
      this.$router.push({ name: 'ruleDetail', params: { alarmRuleSn, page } });
    },
    goRuleInsert() {
      this.$router.push({ name: 'ruleInsert' });
    },
  },
  computed: {
    alarmRuleItems() {
      return this.$store.state.subscription.alarmRuleItems;
    },
  },
};
</script>

<style scoped>
@import url('./rule.css');
</style>
